<template>
  <div cols="12" id="pixiDev" @mousewheel.prevent="scroll" class="rounded-0"></div>
</template>

<script>
// import new_hallDBData from "@/assets/json/new_HallData.json"
import { Renderer, Texture, Sprite, Text, TextStyle,BaseTexture,Rectangle } from 'pixi.js';
import { Viewport } from 'pixi-viewport'
import { Graphics } from "@pixi/graphics";
import {Ticker} from "@pixi/ticker";
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "pixiEditorHall",
  components:[Renderer,Texture,Sprite,Text,TextStyle,Viewport,Graphics,BaseTexture,Rectangle],
  props: {
    dataHall:Object
  },
  data: () =>({
    db_data:{},
    realHall:{},
    realHallFull:{},
    collisionIDs:[],
    flags:{
      componentCreate:false,
      viewportLoaded:false,
      baseCreated:false,
    },
    base: {
      ticker: new Ticker,
      app: null,
      viewport:null,
      loader:null,
      border:null,
    },
    chairsData:{
      interactive:[],
      chairs:[],
      selectedOne: {},
      selected:[]
    },
    spritesTexture: {
      preloader: Texture.from('/img/preloader1.png'),
      background: null,
      sprite: null
    },
    zoneColors: [0x000000,0xF44336,0x2196F3],

    db_word:[]
  }),
  computed: {
    ...mapGetters([
      "getProfileInfo",
      "getSeatColorPixiArr"
    ]),
  },
  mounted() {
    console.warn("created")
    this.$emit('startHallAction')
    // if(this.$props.dataHall) this.db_data = this.$props.dataHall
    // this.stepOne_sizeCanvas()
    // this.startToPrint("reCreate")
    this.zoneColors = this.getSeatColorPixiArr;
  },
  methods:{
    stepOne_sizeCanvas(dataHall,seatRaw){
      // if(this.$props.dataHall) this.db_data = this.$props.dataHall
      this.realHall = dataHall
      this.realHallFull = seatRaw
      let _ww = document.getElementById('pixiDev').offsetWidth;
      let _hh = window.innerHeight;

      if(_ww > 0 && _hh > 0){
        this.stepTwo_canvasCreated(_ww,_hh)
      }

    },
    stepTwo_canvasCreated(_ww,_hh){
      this.appPrint(_ww,_hh)
      // this.viewportPrint("create",this.db_data.bg.size[0],this.db_data.bg.size[1])
      this.viewportPrint("create",this.realHall.layout.bg.size[0],this.realHall.layout.bg.size[1])
      window.onresize = () => {
        this.sizeControl()
      }
      this.sizeControl()

      this.update()

      this.flags.viewportLoaded = true;
      this.flags.baseCreated = true;
      // this.seatsPrint()
      // this.containerToCenter()
    },
    // Очистка viewport от кресел/фона/border
    appClear(data, stepSize) {
      let addStep = 0;
      if (stepSize > 0) {
        addStep += stepSize;
      }
      if (data !== null) {
        data.children.splice(addStep, data.children.length);
      }
    },




    startToPrint(type){
      let _ww = document.getElementById('pixiDev').offsetWidth;
      let _hh = document.getElementById('pixiDev').offsetHeight - 100;
      if(_ww !== 0 || _hh !== 0){
        switch(this.flags.baseCreated){
          case true:
            switch(type) {
              case "pre":
                console.log("pre - под вопросом необходимости теперь")
              break;
              case "reCreate":
                this.base.viewport.alpha = 0;

                // this.base.loader.x = this.base.viewport.width/2;
                // this.base.loader.y = this.base.viewport.height/2;
                this.sizeControl();
                this.appClear(this.base.viewport,'2');
                // this.сastling("load")
                this.base.viewport.alpha = 1;
                break;
            }
            break;
        }
        switch(type){
          case "create":
            if(this.event_raw.b && this.flags.viewportLoaded){
              setTimeout(() => {
                // this.сastling("loaded")
                // this.$emit('loadedStatus', "loaded");

                this.seatsPrint();

                // this.sizeFitter(_ww,_hh);
              }, 500)
            }
            break;
          case "reCreate":
            if(this.flags.baseCreated && this.flags.viewportLoaded){
              // this.clearSelected("all")
              this.appClear(this.base.viewport,'2');
            }
            if (this.flags.viewportLoaded) {
              setTimeout(() => {
                // this.сastling("loaded")
                // this.$emit('loadedStatus', "loaded");
                this.seatsPrint();
                // this.sizeFitter(_ww,_hh);
              }, 500)
            }
            break;
        }
      }
    },






    rotateAnimation() {
      this.base.loader.rotation += 0.04;
      this.base.app.render(this.base.viewport)
    },
    preLoader(_ww,_hh){
      this.base.loader = new Sprite(this.spritesTexture.preloader)

      this.base.loader.anchor.set(0.5);
      this.base.loader.width = 256;
      this.base.loader.height = 256;
      this.base.loader.x = _ww/2;
      this.base.loader.y = _hh/2;
      this.base.loader.alpha = 1;
      this.base.loader.id = 'loader';

      this.base.ticker.add(this.rotateAnimation)
      this.base.ticker.start();
      this.base.viewport.addChild(this.base.loader);
    },

    //Очистка выбранных кресел, с очисткой их за пределами PixiHall/локально снят выбор
    // clearSelected(type) {
    //   switch (type) {
    //     case "all":
    //       //Сценарий только для стартовой отрисовки, так как убивает еще и интерактивные кресла текущего зала
    //       this.chairsData.Selected.splice(0, this.chairsData.Selected.length);
    //       this.chairsData.Interactive.splice(0, this.chairsData.Interactive.length)
    //       // this.$emit('seatToOrder', this.chairsData.Selected);
    //       break;
    //     case "pixi":
    //       //Очищает только выбранные кресла внутри Pixi но не в других компонентах,
    //       //сценарий на случай, если из других мест выбранные кресла убиваются другими функциями
    //       this.chairsData.Selected.forEach((item) => {
    //         let finded = this.chairsData.Interactive.find(el => el.id === item.id)
    //         finded.alpha = 0.9;
    //         finded.scale.x -= 0.05;
    //         finded.scale.y -= 0.05;
    //         finded.tint = finded.originalColor;
    //       })
    //       this.chairsData.Selected.splice(0, this.chairsData.Selected.length);
    //     break;
    //   }
    // },

  getChange(select){
    // console.warn("getChange select")
    // console.log(select)
    // console.log(this.base.viewport.children)
    let found = this.base.viewport.children.find(el=>el.id === select.id)
    found.x = Number(select.coords[0])
    found.y = Number(select.coords[1])
    // found = this.db_data.seats.find(el=>el.id === select.id)
    found = this.realHall.seat_positions.find(el=>el.id === select.id)
    found.coords = [Number(select.coords[0]),Number(select.coords[1])]
  },

  clearAllSelect(){
    this.base.viewport.children.forEach(el=>{
      if(el.typeSprite !== "chair") return;
      el.alpha = 0.9;
      el.tint = el.originalColor;
    })
  },
  getSelect(select){
    // console.log(select)
    let found = this.base.viewport.children.find(el=>el.id === select.id)
    // this.onSelectSeat(found.id,"svg")
    found.tint = 0xffd700;
    found.alpha = 1;
    // console.log(found)
  },

    // rowUpdate(data){
    //   console.warn(data)
    // },



    realChairsPrint(data){
      console.warn("realChairsPrint")
      console.warn(data)
      this.realHall = data
      if(this.realHall.seat_positions){
        console.warn("seat_positions")
        console.log(this.realHall.seat_positions)
        this.realHall.seat_positions.forEach((el)=>{
          // console.warn(el)
          this.singleChairPrint(el)
        })
      }
    },

    addNewSeats(newChair){
      this.singleChairPrint(newChair)
    },

    getListIdx(str, substr) {
      let listIdx = []
      let lastIndex = -1
      while ((lastIndex = str.indexOf(substr, lastIndex + 1)) !== -1) {
        listIdx.push(lastIndex)
      }
      return listIdx
    },
    compareNumbers(a, b) {
      return a - b;
    },
    decodeSVG(svgCode){
      let svgDecode = {
        allIndex:[],
        letterIndex:[],
        result:[]
      }

      let letters = ["M","L","H","V","C","S","Q","T","A","Z"]

      letters.forEach(el=>{
        let res = this.getListIdx(svgCode, el)
        if(res.length > 0) {
          let resValue = {name: el, indexes: res,values:[]}
          svgDecode.letterIndex.push(resValue)
        }
      })
      svgDecode.letterIndex.forEach(el=>{
        el.indexes.forEach(index=>svgDecode.allIndex.push(index))
      })

      svgDecode.allIndex.sort(this.compareNumbers)

      for(let i = 0;i<svgDecode.allIndex.length;i++){
        let value = svgCode.substr(svgDecode.allIndex[i]+1, svgDecode.allIndex[i+1]-svgDecode.allIndex[i]-1)
        let nameFound = svgDecode.letterIndex.find(el=>el.indexes.find(index => index === svgDecode.allIndex[i]))

        value = value.split(" ")
        let numbers = []
        value.forEach(el=>{numbers.push(Number(el))})

        if(i===0){
          svgDecode.result.push({letter:"M",value:numbers})
        }else{
          svgDecode.result.push({letter:nameFound.name,value:numbers})
        }
      }

      return svgDecode.result;
    },

    graphicsChairPrint(chair){
      let t = new Graphics();

      // console.log(chair.graphic)
      let graphic = this.decodeSVG(chair.graphic)
      // this.decodeSVG(chair.graphic)

      t.beginFill(0xD9D9D9);
      t.alpha = 0.5;

      graphic.forEach(el=>{
        switch(el.letter){
          case "M":
            t.moveTo(el.value[0],el.value[1])
          break;
          case "C":
            t.bezierCurveTo(el.value[0],el.value[1],el.value[2],el.value[3],el.value[4],el.value[5]);
          break;
          case "Z":
          break;
          case "V":
            console.log(el)
            console.log("ряд "+ chair.row + ' место ' + chair.chair)
          break;
          case "L":
            t.lineTo(el.value[0],el.value[1])
          break;
          default:
            console.warn("Найдена векторная команда без сценария")
            console.warn(el)
        }
      })

      // t.moveTo(0.717312,21.0001)
      // t.bezierCurveTo(0.717312,14.5001,13,14.0001,14.001,14);
      // t.bezierCurveTo(15.002,13.9999,15.502,6.99981,16.501,2.99993);
      // t.bezierCurveTo(17.5,-0.999947,50.501,0.49981,53.501,2.99943);
      // t.bezierCurveTo(56.501,5.49905,55.501,14,53.501,16.4999);
      // t.bezierCurveTo(51.501,18.9999,50.001,18.0001,49.001,21.9999);
      // t.bezierCurveTo(48.001,25.9998,46.502,53.9998,46.501,58.4999);
      // t.bezierCurveTo(46.5,63,46.501,63,41,63);
      // t.bezierCurveTo(35.499,63,7.28199,63,3.99978,63);
      // t.bezierCurveTo(0.717569,63,0.717372,63,0.717312,56.5);
      // t.bezierCurveTo(0.717252,50,0.717312,27.5001,0.717312,21.0001);

      t.position.x = chair.coords[0];
      t.position.y = chair.coords[1];

      let addition = this.zoneFind(chair.id)

      t.id = chair.id;
      t.alpha = 0.9;
      t.x = chair.coords[0];
      t.y = chair.coords[1];

      t.tint = chair.tint;
      t.originalColor = chair.tint;
      t.interactive = true;
      t.price = "---"
      if(addition && addition.b){
        t.zone = addition.zone;
        t.price = addition.price
        t.tint = this.zoneColors[addition.zone]
        t.originalColor = this.zoneColors[addition.zone];
      }

      // t.zone = this.zoneFind(chair.id);
      console.warn(chair.zone)

      // t.price = this.priceFind(chair.zone);
      t.typeSprite="chair"

      // if (t.interactive) {
      //   this.chairsData.Interactive.push(t)
      // }
      t.seatData = {x: t.x, y: t.y, row: chair.row, chair: chair.chair, price: t.price}
      t.on('pointerover', () => {
        let checkStat = this.checkerSeat(t.id);
        switch (checkStat[0]) {
          case false:
            this.textInfoData(true, t.seatData)
            t.alpha = 0.7;
            break;
        }
      })
      t.on("pointerout", () => {
        let checkStat = this.checkerSeat(t.id);
        switch (checkStat[0]) {
          case false:
            this.textInfoData(false)
            t.alpha = 0.9;
            break;
        }
      })
      t.on('pointerdown', () => {
        // this.SeatClickTouch(t, "pointerdown");
        this.SeatClickTouch(t, "pointerdown")
        this.onSelectSeat(t.id,"svg")
        this.textInfoData(false)
      });
      this.chairsData.interactive.push(t)
      this.chairsData.chairs.push(t);
      this.base.viewport.addChild(t)



    },
    singleChairPrint(chair){
      // let chairTexture = Texture.from(this.db_data.bg.path+ "/" + chair.svg + ".svg")
      // let chairTexture = Texture.from(this.realHall.layout.bg.path+ "/" + chair.svg + ".svg")
      let chairTexture = Texture.from(this.realHall.layout.bg.path+ "/" + chair.svg + ".svg")
      let t = new Sprite(chairTexture)

      let addition = this.zoneFind(chair.id)

      t.id = chair.id;
      t.alpha = 0.9;
      t.x = chair.coords[0];
      t.y = chair.coords[1];

      t.tint = chair.tint;
      t.originalColor = chair.tint;
      t.interactive = true;
      t.price = "---"
      if(addition.b){
        t.zone = addition.zone;
        t.price = addition.price
        t.tint = this.zoneColors[addition.zone]
        t.originalColor = this.zoneColors[addition.zone];
      }

      // t.zone = this.zoneFind(chair.id);
      console.warn(chair.zone)

      // t.price = this.priceFind(chair.zone);
      t.typeSprite="chair"

      // if (t.interactive) {
      //   this.chairsData.Interactive.push(t)
      // }
      t.seatData = {x: t.x, y: t.y, row: chair.row, chair: chair.chair, price: t.price}
      t.on('pointerover', () => {
        let checkStat = this.checkerSeat(t.id);
        switch (checkStat[0]) {
          case false:
            this.textInfoData(true, t.seatData)
            t.alpha = 0.7;
            break;
        }
      })
      t.on("pointerout", () => {
        let checkStat = this.checkerSeat(t.id);
        switch (checkStat[0]) {
          case false:
            this.textInfoData(false)
            t.alpha = 0.9;
            break;
        }
      })
      t.on('pointerdown', () => {
        // this.SeatClickTouch(t, "pointerdown");
        this.SeatClickTouch(t, "pointerdown")
        this.onSelectSeat(t.id,"svg")
        this.textInfoData(false)
      });
      this.chairsData.interactive.push(t)
      this.chairsData.chairs.push(t);
      this.base.viewport.addChild(t)
    },
    zoneFind(id){
      if(!this.realHallFull) return;
      let found = this.realHallFull.data.seats.find(el=>el.id===id);
      if(!found) return;
      // console.warn("found")
      // console.warn(found)
      let price = this.priceFind(found.zone)
      return {b:!!found,zone:found.zone,price:price}
    },
    priceFind(zone) {
      return this.realHallFull.data.prices.find(el => el.zone === zone).price;
    },

    searchCollisions(){
      console.warn(this.base.viewport.children.length)
      this.base.viewport.children.forEach(el=>{
        if(!el.texture) return;
        if(el.id === 'loader') return;
        if(el.id === 'background') return;
        if(!el.id) return;

        let found = this.base.viewport.children.find(item=>((item.x > el.x && item.x < el.x + el.texture.width) && (item.y > el.y && item.y < el.y + el.texture.height) && item.id !== el.id))
        //
        if(found) {
          this.collisionIDs.push(found.id)
        }
      })
      this.collisionIDs.forEach(el=>{
        this.base.viewport.children.find(item=>item.id === el).tint = 0xffffff;
      })
    },

    seatsPrint(){
      this.backgroundPrint()

      // if(!this.db_data) return;
      // if(!this.db_data) return;
      if(!this.realHall) return;

      // console.warn("seat_positions")
      // console.warn(this.realHall)
      // console.warn(this.$props.dataRealHall)
      // // if(this.realHall.data.seat_positions){
      // //   this.realHall.data.seat_positions.forEach((el)=>{
      // //     console.warn(el)
      // //   })
      // // }

      this.hallChairsSpritePrint(this.realHall.layout.sprite)

      setTimeout(()=>{
        this.realHall.seat_positions.forEach((el)=>{
          // this.db_data.seats.forEach((el)=>{
          // this.singleChairPrint(el)
          this.graphicsChairPrint(el)
          // if(index > 2) return;
          // // this.singleChairFramePrint(el)
          // switch(el.id){
          //   case 3770:
          //   case 3771:
          //   case 3772:
          //     this.singleChairFramePrint(el)
          //   break;
          // }
        })
        console.warn(_.union(this.db_word))
        this.textInfo();

        setTimeout(()=>{
          this.searchCollisions()
        },1000)
      },500)
    },
    //проверка выделенности кресла
    // checkerSeat(id) {
    //   //проверка выбранных мест, что бы не перебивать эффект выделения
    //   let searchActiveSeat = this.chairsData.Selected.findIndex(el => el.id === id);
    //   let selected = [false, id, searchActiveSeat];
    //   switch (searchActiveSeat) {
    //     case -1:
    //       selected[0] = false;
    //       break;
    //     default:
    //       selected[0] = true;
    //   }
    //   return selected;
    // },
    //Создание текстовой подсказки
    textInfo() {
      let style = new TextStyle({
        fontFamily: 'Arial',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fill: '#ffffff',
        wordWrap: true,
        wordWrapWidth: 180,
        lineJoin: 'round',
      });

      let {textInfo, textBack} = this.base;
      textInfo = new Text('', style);
      textInfo.id = 'seatText';

      textBack = new Graphics();

      textBack.lineStyle(2, 0xCCCCCC, 0.25);
      textBack.beginFill(0x000000);
      textBack.drawRoundedRect(0, 0, 180, 44, 16);
      textBack.alpha = 0;
      textBack.endFill();
      textBack.id = "textBack";

      this.base.viewport.addChild(textBack);
      this.base.viewport.addChild(textInfo);
    },
    onSelectSeat(id,type){
      this.onMutateSelect(this.chairsData.selectedOne.id,false)
      switch(type){
        case "svg":
          // this.chairsData.selectedOne = this.db_data.seats.find(el=>el.id === id)
          this.chairsData.selectedOne = this.realHall.seat_positions.find(el=>el.id === id)
          this.$emit('onSelectSeat',this.chairsData.selectedOne.id,"svg")
        break;
        case "chips":
          // this.chairsData.selectedOne = this.db_data.seats.find(el=>el.id === id)
          this.chairsData.selectedOne = this.realHall.seat_positions.find(el=>el.id === id)
        break;
        default:
          this.chairsData.selectedOne = {}
      }
      if(!this.chairsData.selectedOne) return;
      setTimeout(()=>{
        this.onMutateSelect(this.chairsData.selectedOne.id,true)
      },50)
    },
    onMutateSelect(id,type){
      let found = this.base.viewport.children.find(el=>el.id === id)
      if(!found) return;
      switch (type) {//снимаем выделение или включаем
        case false:
          found.tint = found.originalColor;
          found.alpha = 0.9;
        break;
        case true:
          found.tint = 0xffd700;
          found.alpha = 1;
          if(found._texture.baseTexture.valid) return;//Если спрайт не валидный продолжаем

          console.warn(found._texture.baseTexture.valid)
          this.$emit('onChairProblem',id)
          // found = this.db_data.seats.find(el=>el.id === id)
          found = this.realHall.seat_positions.find(el=>el.id === id)

          if(!found) return;
          found.spriteError = true;
        break;
      }
    },




//Изменение текстовой подсказки
    textInfoData(flag,data){
      let seat = this.base.viewport.children.find(el=>el.id === "seatText");
      let seatBack = this.base.viewport.children.find(el=>el.id === "textBack");
      switch(flag){
        case true:
          seat.text = data.row + " ряд, " + data.chair + " место. \nЦена: " + data.price + " руб.";
          seat.alpha = 1;
          seat.x = data.x;
          seat.y = data.y + 35;
          seat.anchor.set(0.5);

          seatBack.alpha = 0.6;
          seatBack.x = data.x - seatBack.width / 2;
          seatBack.y = data.y + 14;
          break;
        case false:
          seat.alpha = 0;
          seatBack.alpha = 0;
          seat.text = '';
          break;
      }
    },
    SeatClickTouch(t, eventType) {
      let last_seat = {
        id: t.id,
        selected: false, // было выделено, или снято
      };

      //определяем какой фильтр к креслу применить в зависимости от ситуации на текущий момент
      // let seatInfo = {id: t.id, price: t.price, zone: t.zone}
      let seatInfo = {id: t.id, price: "0", zone: "f"}
      let checkStat = this.checkerSeat(t.id);
      switch (eventType) {
        case "pointerdown":
          switch (checkStat[0]) {
            case true:
              this.chairsData.selected.splice(checkStat[2], 1);
              // t.scale.x -= 0.05;
              // t.scale.y -= 0.05;
              t.tint = t.originalColor;
              t.alpha = 0.9;
            break;
            default:
              this.chairsData.selected.push(seatInfo);
              // t.scale.x += 0.05;
              // t.scale.y += 0.05;
              t.tint = 0xffd700;
              t.alpha = 1;
              last_seat.selected = true;
          }
          // this.$emit('seatToSelected', this.chairsData.Selected, last_seat);
        break;
      }
    },
    //проверка выделенности кресла
    checkerSeat(id) {
      //проверка выбранных мест, что бы не перебивать эффект выделения
      let searchActiveSeat = this.chairsData.selected === id;
      let selected = [false, id, this.chairsData.selected];
      switch (searchActiveSeat) {
        case false:
          selected[0] = false;
          break;
        default:
          selected[0] = true;
      }
      return selected;
    },

    //Отрисовка бекграунда зала
    hallChairsSpritePrint(sprite){
      this.spritesTexture.sprite = BaseTexture.from(sprite.path+"/"+this.realHall.layout.id+"/"+sprite.image)
      // this.spritesTexture.sprite = Texture.from(sprite.path+"/"+this.realHall.layout.id+"/"+sprite.image)
    },
    backgroundPrint() {
      // this.spritesTexture.background = Texture.from(this.db_data.bg.path+this.db_data.bg.image)
      this.spritesTexture.background = Texture.from(this.realHall.layout.bg.path+"/"+this.realHall.layout.id+"/"+this.realHall.layout.bg.image)
      let back = new Sprite(this.spritesTexture.background)

      back.id = 'background';
      back.anchor.set(0); //документация https://pixijs.download/dev/docs/PIXI.Sprite.html
      back.x = 0;
      back.y = 0;
      back.interactive = false;

      this.base.viewport.addChild(back)
    },
    update() {

      if (this.base.viewport.dirty) {
        this.base.app.render(this.base.viewport)
        this.base.viewport.dirty = false
      }
      requestAnimationFrame(() => this.update())
    },
    sizeControl(type,_ww,_hh){
      switch(type){
        case "data_raw":
          // console.log("coordinate sizeHall")
          // console.log(type + "/" + _ww + "/" + _hh)
        break;
        default:
          _ww = document.getElementById('pixiDev').offsetWidth;
          _hh = document.getElementById('pixiDev').offsetHeight - 100;
      }

      this.base.app.resize(_ww, _hh)
      this.base.viewport.resize(_ww, _hh)

      this.containerToCenter()
    },
    //Центрирование
    containerToCenter(){
      this.base.viewport.fit();
      // if(this.event_raw.b) {
      //   this.base.viewport.moveCenter(this.event_raw.data.layout.width / 2, this.event_raw.data.layout.height / 2)
      // }else{
      // let _ww = document.getElementById('pixiDev').offsetWidth;
      // let _hh = document.getElementById('pixiDev').offsetHeight - 100;

      this.base.viewport.moveCenter(this.realHall.layout.bg.size[0] / 2, this.realHall.layout.bg.size[1] / 2)
      // this.base.viewport.moveCenter(this.db_data.bg.size[0] / 2, this.db_data.bg.size[1] / 2)
      // }
    },
    appPrint(_ww,_hh){
      this.base.app = new Renderer({
        antialias: true,
        autoDensity: true,
        width: _ww,
        height: _hh,
        backgroundAlpha: 0,
        resolution: window.devicePixelRatio || 1,
      });
      // this.$el.appendChild(this.base.app.view);
      document.getElementById('pixiDev').appendChild(this.base.app.view);
      this.flags.baseCreated = true;
    },
    //create viewport
    viewportPrint(type,_ww,_hh){
      this.base.viewport = new Viewport({
        //https://davidfig.github.io/pixi-viewport/ - возможные опции с комментариями
        worldWidth: _ww,
        worldHeight: _hh,
        passiveWheel: false,
        interaction: this.base.app.plugins.interaction,
        disableOnContextMenu: true,
      });

      this.base.viewport
          .drag()
          .pinch({
            noDrag: true,
          })
          .wheel()
          .decelerate()
      // .clampZoom({
      //   minScale: 1.5,                 // minimum scale
      //   maxScale: 1.9,                 // minimum scale
      // })

      this.preLoader(_ww,_hh);
      this.border();

      this.base.viewport.fit()
      this.base.viewport.moveCenter(_ww / 2, _hh / 2)

    },
    border() {
      this.base.border = new Graphics();
      this.base.border.lineStyle(1, 0xff0000).drawRect(0, 0, this.base.viewport.worldWidth, this.base.viewport.worldHeight)
      this.base.viewport.addChild(this.base.border);
    },
    //Блокировка прокрутки страницы при зуме колесиком
    scroll(e) {
      e = e || window.event;
      if (e.preventDefault)
        e.preventDefault();
      e.returnValue = false;
    },
    //проба ресайза по принципу меньшей из сторон
    sizeFitter(ww,hh){
      if(ww>hh){
        this.base.viewport.fitHeight(hh,true);
      }else{
        this.base.viewport.fitWidth(ww,true);
      }
    },
  }
}
</script>

<style scoped>

</style>