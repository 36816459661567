<template src="./pixiDev.html"></template>

<script>
import pixiEditorHall from "./pixiEditorHall.vue"
import _ from "lodash";

export default {
  name: "PixiDev",
  components: {pixiEditorHall},
  data: () => ({
    db_data: {},
    createMode:false,
    createCopyMode:false,
    newChairReady:false,
    rowEdit:false,
    message:{
      text:"Шаблон формы",
      type:"error",
      flag:true
    },
    sectors:[],
    rows:[],
    real_hall:{},
    hallReady:false,
    id_pack:{
      show_id:    0,
      event_id:   696,
      culture_id: 0,
      theater:    0,
      mo_id:      0,
    },
    seatRaw:{},
    empty_chair:{
      id:"",
      svg:"",
      tint:"0xFFFFFF",
      status:"active",
      isActive:true,
      zone:"",
      chair:"",
      row:"",
      sector_id:"",
      coords:[0,0],
      spriteError: false
    },
    selected:{
      id:"",
      svg:"",
      tint:"0xFFFFFF",
      status:"active",
      isActive:true,
      zone:"",
      chair:"",
      row:"",
      sector_id:"",
      coords:[0,0],
      spriteError: false
    },
    groupEdit:false,
    groupSelect:[],
    rowSelect: {sector:null,row:null},
    rowCordsMove:[0,0],
    coordinate:[0,0],

    keyEditor:0,
  }),
  created() {
    // this.db_data = new_hallDBData
    // this.createFilters()
    // this.loadRealHall()
    // this.loadRealHall()
    this.loadRealHallFull()
  },
  mounted() {
    // this.startHallAction()
  },
  methods:{
    startHallAction(){
      console.log("this.real_hall")
      console.log(this.real_hall)
      console.warn(this.seatRaw)
      this.$refs.pixiEditorHall.stepOne_sizeCanvas(this.real_hall,this.seatRaw)
      this.$refs.pixiEditorHall.startToPrint("pre")
      this.$refs.pixiEditorHall.startToPrint("reCreate")
    },
    createFilters(){
      // this.db_data.seats.forEach(el=>{
      this.real_hall.seat_positions.forEach(el=>{
        this.rows.push(el.row)
        // this.sectors.push(el.sector_id)
        let foundSector =  this.sectors.find(sector=>sector.sector_id === el.sector_id)
        //
        if(foundSector){
          foundSector.rows.push(el.row)
        }else{
          this.sectors.push({sector_id: el.sector_id,rows:[el.row]})
        }
      })
      this.rows = _.union(this.rows)
      // this.rows = _.union(this.rows)
      this.sectors.forEach(el=>{
        el.rows = _.sortBy(_.union(el.rows))
      })
      // this.sectors = _.union(this.sectors)

      console.log("this.sectors")
      console.log(this.sectors)
    },
    loadRealHallFull(){
      let pr = this.$store.state.api.getEventInet(this.id_pack.event_id, this.id_pack.mo_id);

      pr.then( data =>{
        console.warn(data)
        this.seatRaw.b = true;
        this.seatRaw.data = data;
        this.loadRealHall()
      })
    },
    loadRealHall(){
      console.warn("LOAD")
      console.warn(this.real_hall)


      // fetch('https://api.zakaz.cloud/layout/28?key=KJLHDLKF09ulkdst&version=2')
      fetch('/new-edit-hall.json')
      .then(response => response.json())
      // .then(response => response)
      .then( (data) =>{
        console.warn("local data")
        console.log(data)
        // if(data.b !== "ITEM_ID") return;
        if(!data.layout) return;

        let customHall = {
          layout:data.layout,
          seat_positions:[]
        }

        // let customHall = {
        //   layout:data.data.layout,
        //   seat_positions:[]
        // }

        customHall.layout.bg = {
          image: "/hall.jpg",
          size: [2441,1080],
          path: "realHall"
        }

        // data.data.seat_positions.forEach(el=>{
        data.seat_positions.forEach(el=>{
          // console.warn(el)
          let updateEl = el
          // if(!updateEl.coords) updateEl.coords = [el.left,el.top]
          updateEl.coords = !updateEl.coords ? [el.left,el.top] : el.coords

          if(el.id === 3813) console.error(el)
          // if(!updateEl.svg)updateEl.svg = "1-1-1"
          if(!updateEl.svg)updateEl.svg = data.data.layout.id +"/"+ el.sector_id +"-"+ el.row +"/"+ el.sector_id +"-"+ el.row +"-"+ el.chair
          if(!updateEl.tint)updateEl.tint = "0xFFFFFF"
          if(!updateEl.spriteError)updateEl.spriteError = false
          if(!updateEl.status)updateEl.status = "active"
          if(!updateEl.isActive)updateEl.isActive = true

          customHall.seat_positions.push(updateEl)
        })

        this.real_hall = customHall

        this.hallReady = true; //услованая проверка флага готовности

        // this.startHallAction(this.real_hall)

        this.createFilters()


        // console.warn("this.real_hall")
        // console.warn(this.real_hall)

        // if(this.hallReady) this.$refs.pixiEditorHall.realChairsPrint(this.real_hall)

        // console.warn("load server hall")
        // console.warn(this.real_hall)
      })
    },
    //load real data hall https://api.zakaz.cloud/layout/28?key=KJLHDLKF09ulkdst&version=2

    groupSectorSelect(sector){
      this.$refs.pixiEditorHall.clearAllSelect()
      this.rowSelect = {row:null,sector:sector}

      let s = Number(this.rowSelect.sector)

      let founds = _.filter(this.real_hall.seat_positions,function(item){
        return item.sector_id === s
      })
      founds.forEach(el=>{
        this.$refs.pixiEditorHall.getSelect(el)
      })
    },
    groupRowSelect(sector,row){
      this.$refs.pixiEditorHall.clearAllSelect()

      if(this.rowSelect.row === row && this.rowSelect.sector === sector){
        this.rowSelect = {row:null,sector:null}
      }else{
        this.rowSelect = {row:row,sector:sector}
      }

      let s = Number(this.rowSelect.sector)
      let r = Number(this.rowSelect.row)
      let founds = _.filter(this.real_hall.seat_positions,function(item){
        return item.row === r && item.sector_id === s
      })
      founds.forEach(el=>{
        this.$refs.pixiEditorHall.getSelect(el)
      })
    },
    groupChange(add,index,value){

      if(!this.rowEdit && !this.groupEdit) return;

      let founds
      if(this.rowEdit){
        if(this.rowSelect.sector && this.rowSelect.row !== null){
          let s = Number(this.rowSelect.sector)
          let r = Number(this.rowSelect.row)
          founds = _.filter(this.real_hall.seat_positions,function(item){
            return item.row === r && item.sector_id === s
          })
        }else if(this.rowSelect.sector && this.rowSelect.row === null){
          let s = Number(this.rowSelect.sector)
          founds = _.filter(this.real_hall.seat_positions,function(item){
            return item.sector_id === s
          })
        }
      }
      if(this.groupEdit){
        founds = this.groupSelect
      }

      console.log(founds)
      if(founds.length < 1) return;

      founds.forEach(el=>{
        if(add){
          el.coords[index] += value
        }else{
          el.coords[index] -= value
        }
        this.$refs.pixiEditorHall.getChange(el)
      })
    },

    onSelectColor(id){
      if(!this.groupEdit) return false;
      let found = this.groupSelect.find(el=>el.id === id)
      return found ? true : false;
    },

    onGroupSelect(chair){
      console.log(chair)
      let found = this.groupSelect.findIndex(el=>el.id === chair.id)
      if(found === -1) {
        this.groupSelect.push(chair)
      }else{
        this.groupSelect.splice(found,1)
      }

      this.$refs.pixiEditorHall.clearAllSelect()

      this.groupSelect.forEach(el=>{
        this.$refs.pixiEditorHall.getSelect(el)
      })
    },

    onSelectSeat(id,type){
      this.onMessageEvent()
      let found = this.real_hall.seat_positions.find(el=>el.id === id)
      // let found = this.db_data.seats.find(el=>el.id === id)
      let staticRes = _.cloneDeep(found)

      if(this.groupEdit){
        this.onGroupSelect(staticRes);
        return;
      }
      switch(this.createMode){
        case true:
          if(!staticRes) return;
          switch(this.createCopyMode){
            case true:
              this.coordinate = staticRes.coords
              this.selected = staticRes
            break;
            case false:
              this.coordinate = staticRes.coords
            break;
          }
        break;
        case false:
              // this.selected = this.db_data.seats.find(el=>el.id === t.id)
          switch(type) {
            case "svg":
              this.selected = staticRes
            break;
            case "chips":
              this.selected = staticRes
              this.$refs.pixiEditorHall.onSelectSeat(this.selected.id,"chips")
            break;
          }
          // this.$refs.pixiEditorHall.onSelectSeat(this.selected.id,"chips")
          if(!this.selected) return;
          console.warn(this.coordinate)
          console.warn(this.selected.coords)
          this.coordinate = this.selected.coords

          if(!this.selected.spriteError) return;

          this.message.text = "Кресло с проблемой в отрисовке"
          this.message.type = "error"
          this.message.flag = true;
        break;
      }
    },
    seatToSelected(seats, last_seat){ //TODO возможно удалить функцию
      console.warn("seatToSelected")
      console.warn(seats)
      console.warn(last_seat)
    },
    onChairProblem(id){
      console.log(id)
      // let found = this.db_data.seats.find(el=>el.id === id)
      let found = this.real_hall.seat_positions.find(el=>el.id === id)
      if(!found) return;
      found.spriteError = true;
    },
    saveNewChair(){
      if(!this.selected.zone) this.selected.zone = this.selected.sector_id //Дефолтное заполнение
      // this.db_data.seats.push(this.selected) //Пушим в массив данных
      this.real_hall.seat_positions.push(this.selected) //Пушим в массив данных
      this.$refs.pixiEditorHall.addNewSeats(this.selected) //закидываем в холст
      this.createMode = false;
      this.createCopyMode = false;
      this.onSelectSeat(this.selected.id,"chips")
    },
    addChair(){
      this.groupEdit = false;
      this.rowEdit = false;
      let newChair = _.cloneDeep(this.empty_chair)
      newChair.id = "new_chair"
      Object.assign(this.selected,newChair)
      Object.assign(this.coordinate,this.empty_chair.coords)
      this.createMode = true;

      //Сначала при нажадии add - очистить форму, и дать возможность ее заполнить, при save запушить элемент в массив

    },
    onFormClear(){
      let emptyChair = _.cloneDeep(this.empty_chair)
      Object.assign(this.coordinate,emptyChair.coords)
      Object.assign(this.selected,emptyChair)
      // Object.assign(this.selected,this.empty_chair)
      this.createMode = false;
      this.createCopyMode = false;
    },

    saveHall(){
      let jsonse = JSON.stringify(this.real_hall);
      let blob = new Blob([jsonse], {type: "application/json"});
      let url  = URL.createObjectURL(blob);

      const link = document.createElement('a')

      link.setAttribute('href',url)
      link.setAttribute('download', "hall")
      link.style.display = 'none'

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
    },
    onMessageEvent(){
      this.message.text = "Все хорошо"
      this.message.type = "error"
      this.message.flag = false;
    },


    //Подсветка ценовых зон
    seatColorZone(data) {
      if (data === "all") {
        this.chairsData.Interactive.forEach((item) => {
          let selected = this.chairsData.Selected.find(el => el.id === item.id);
          if (selected) {
            return;
          }
          item.tint = this.chairsData.zoneColors[item.zone]
        })
      } else {
        this.chairsData.Interactive.forEach((item) => {
          let selected = this.chairsData.Selected.find(el => el.id === item.id);
          if (selected) {
            return;
          }
          if (item.zone !== data) {
            item.tint = 0x333333;
          } else {
            item.tint = this.chairsData.zoneColors[item.zone]
          }
        })
      }
    },
  },
  watch: {
    groupEdit: function(){
      this.$refs.pixiEditorHall.clearAllSelect()
      Object.assign(this.selected,this.empty_chair)
      this.groupSelect.splice(0)
    },
    rowSelect: function(){
      this.rowCordsMove = [0,0]
    },
    coordinate: function(){
      console.log(this.selected.id,this.selected.coords,this.coordinate)
      if(!this.createMode){
        this.$refs.pixiEditorHall.getChange(this.selected)
      }else{
        this.selected.coords = this.coordinate
      }
      // this.keyEditor++
    },
    selected:{
      handler: function(){
        if(!this.createMode) return
        if(this.selected.row && this.selected.chair && this.selected.sector_id){
          this.selected.id = this.selected.sector_id+"-"+this.selected.row+"-"+this.selected.chair
          this.selected.svg = this.selected.sector_id+"-"+this.selected.row+"-"+this.selected.chair

          // let foundID = this.db_data.seats.find(el=>el.id===this.selected.id)
          let foundID = this.real_hall.seat_positions.find(el=>el.id===this.selected.id)

          this.newChairReady = !foundID

          this.message.text = !this.newChairReady && foundID ? "кресло с такими данными уже имеется!" : "" ;
        }else{
          this.newChairReady = false;
          this.message.text = ""
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>